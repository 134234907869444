<template>
  <div class="post-edit-box">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label :for="'ddlJournals_' + post_id" class="col-form-label">Journal</label>
          <select class="form-control"
                  v-model="selectedJournalId"
                  :id="'ddlJournals_' + post_id">
            <option value="">The Wall</option>
            <option v-for="j in all_journals"
                    :key="j.id"
                    :value="j.id">
              {{j.name}} (@{{j.tag}})
            </option>
          </select>
        </div>

        <div class="form-group">
          <label :for="'txtMessage_' + post_id" class="col-form-label">Post</label>
          <textarea class="form-control"
                    :id="'txtMessage_' + post_id"
                    autocomplete="off"
                    v-model="postText"
                    style="height: 200px;"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label :for="'txtTimestamp_' + post_id"
                 class="col-form-label">Log Date Time (optional)</label>
          <input type="text" :id="'txtTimestamp_' + post_id"
                 v-model="postTimestamp"
                 class="date-time-picker wide form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col edit-buttons">
        <button type="button"
                :disabled="save_in_progress"
                @click="saveEdit()" class="btn btn-sm btn-primary">
          <span v-if="save_in_progress">
            <i class="fas fa-spinner-third fa-spin"></i> &nbsp;Saving...
          </span>
          <span v-if="!save_in_progress">Save</span>
        </button>
        <button type="button" @click="cancelEdit()"
                :disabled="save_in_progress"
                class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>

import flatpickr from 'flatpickr';
import moment from 'moment';
import _ from 'lodash';
import CONSTANTS from '@/app/constants';

export default {
  props: ['post', 'journal', 'all_journals', 'save_in_progress'],
  emits: ['onCancel', 'onSave'],
  data() {
    return {
      postText: this.text_entry,
      postTimestamp: moment(this.entry_timestamp)
        .format(CONSTANTS.DATE_TIME_FORMATS.MOMENT_LOG_TIME),
      selectedJournalId: null,
      post_id: null,
    };
  },
  computed: {
    defaultJournalId() {
      // Check if Journal exists
      const doesJournalExist = this.post != null && this.post.journal_id != null
          && _.some(this.all_journals, { id: this.post.journal_id });

      // First return the journal ID of the post
      if (this.post != null && this.post.journal_id != null) {
        return doesJournalExist ? this.post.journal_id : '';
      }

      // Return null if we are in wall and there is no journal already associated with it
      // or if the user has no journals
      if (this.journal == null || this.all_journals == null || this.all_journals.length === 0) {
        return '';
      }

      // Finally, return the current journal
      return this.journal.id;
    },
  },
  methods: {
    cancelEdit() {
      this.$emit('onCancel');
    },
    saveEdit() {
      // Call end point to save the wall post
      this.$emit('onSave', {
        id: this.post == null ? null : this.post.id,
        text_entry: this.postText,
        entry_timestamp: this.postTimestamp,
        journal_id: this.selectedJournalId === '' ? null : this.selectedJournalId,
      });
    },
  },
  mounted() {
    if (this.post == null) {
      this.post_id = '';
    }

    if (this.post == null || !this.post.entry_timestamp) {
      this.postTimestamp = moment().format(CONSTANTS.DATE_TIME_FORMATS.MOMENT_LOG_TIME);
    } else {
      this.postTimestamp = this.post.entry_timestamp;
    }

    this.postText = this.post == null ? '' : this.post.text_entry;

    this.selectedJournalId = this.defaultJournalId;

    setTimeout(() => {
      // Give it sometime to set the correct value in the timestamp textbox
      flatpickr('.date-time-picker', {
        enableTime: true,
        dateFormat: CONSTANTS.DATE_TIME_FORMATS.FLATPICKR_FORMAT,
      });
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
  .edit-buttons {
    button {
      margin-right: 10px;
    }
  }
</style>
