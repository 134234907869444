<template>
  <div :class="{ 'delete-post': delete_mode }" class="row">
    <div class="col-12 wall-post">
      <div v-if="is_delete_operation" class="progress-line red-bar"></div>
      <div v-show="!edit_mode">
        <div class="card w-100">
          <div class="card-body">
            <div class="card-subtitle mb-2 small text-muted clearfix">
              <div class="float-md-left">
                <span :title="renderFullDateTime(post.entry_timestamp)" class="cursor-pointer">
                  {{ renderPrettyTime(post.entry_timestamp) }}</span> via {{ post.via }}
              </div>
              <div class="float-md-right">
                <div class="btn-group journal-toggle">
                  <div :class="{'has-journals' : (all_journals != null && all_journals.length > 0)}"
                       :title="'This was posted to ' + journalName"
                       class="journal-name">
                    <span v-if="!is_journal_move_operation">
                      <i class="fad fa-books"></i>
                      {{ journalName }}
                    </span>
                    <span v-if="is_journal_move_operation">
                      <i class="fas fa-spin fa-spinner-third"></i>
                      Moving journals...
                    </span>
                  </div>
                  <button class="journal-switch-menu"
                          :disabled="is_journal_move_operation"
                          v-if="all_journals != null && all_journals.length > 0"
                          data-toggle="dropdown">
                    <i class="fa fa-chevron-down"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a v-for="j in movableJournalList" v-bind:key="j.id"
                       @click="moveEntryToJournal(j.id)"
                       class="dropdown-item" href="javascript:">
                      <i class="fa fa-share"></i>&nbsp;
                      Move to @{{j.tag}}</a>
                    <div class="dropdown-divider"
                         v-if="post.journal_id != null && movableJournalList.length > 0">
                    </div>
                    <a class="dropdown-item"
                       @click="moveEntryToJournal('')"
                       href="javascript:" v-if="post.journal_id != null">
                      <i class="fa fa-reply"></i>&nbsp;
                      Move to The Wall
                    </a>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-text">
              <div v-html="breakLines(post.text_entry)">
              </div>
            </div>
          </div>
          <div class="card-footer small clearfix">
            <div class="float-none float-md-left post-hashtags">
              <div v-if="post.tags != null && post.tags.length > 0">
                <a v-for="tag in post.tags"
                   :key="tag"
                   href="javascript:"
                   @click="performTagSearch(tag);">{{ tag }}</a>
              </div>
            </div>
            <div class="float-none float-md-right text-left text-md-right mt-2 mt-md-0">
              <a href="javascript:" @click="editWallPost()">Edit</a>
              <a class="ml-3" href="javascript:" @click="deleteWallPost()">Delete</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="edit_mode">
        <div v-if="is_post_operation" class="progress-line"></div>
        <post-edit
            :all_journals="all_journals"
            :journal="journal"
            :post="post"
            :save_in_progress="is_post_operation"
            v-on:onCancel="cancelEdit"
            v-on:onSave="editSaved">
        </post-edit>
      </div>
    </div>
  </div>
  <div v-if="delete_mode" class="delete-confirmation">
    <a class="small red" href="javascript:" @click="deletePost()">
      Click to confirm deletion of this post!</a>&nbsp;
    <a class="small ml-2" href="javascript:"
       @click="cancelDelete()">Cancel</a>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import auth from '@/app/auth';
import journal from '@/app/journal';
import utils from '@/app/utils';
import PostEdit from '@/components/PostEdit.vue';
import CONSTANTS from '@/app/constants';

export default {
  emits: ['post_updated', 'post_deleted', 'tag_search'],
  components: { PostEdit },
  data() {
    return {
      edit_mode: false,
      delete_mode: false,
      is_post_operation: false,
      is_delete_operation: false,
      is_journal_move_operation: false,
    };
  },
  props: [
    'post',
    'journal',
    'all_journals',
  ],
  computed: {
    journalName() {
      if (this.post.journal_id == null) {
        return 'The Wall';
      }

      const postJournal = _.find(this.all_journals, { id: this.post.journal_id });
      if (postJournal === undefined) {
        return 'The Wall';
      }

      return postJournal.name;
    },
    movableJournalList() {
      if (this.all_journals == null) {
        return [];
      }

      if (this.post.journal_id == null) {
        return this.all_journals;
      }

      return _.filter(this.all_journals, (x) => x.id !== this.post.journal_id);
    },
  },
  methods: {
    renderFullDateTime(timestamp) {
      return moment(timestamp)
        .format(CONSTANTS.DATE_TIME_FORMATS.MOMENT_PRETTY_TIME);
    },
    renderPrettyTime(timestamp) {
      return moment(timestamp)
        .calendar({
          sameElse: CONSTANTS.DATE_TIME_FORMATS.MOMENT_PRETTY_TIME,
        });
    },
    breakLines(text) {
      return text == null ? '' : text.replace(/(\r\n|\n|\r)/gm, '<br>');
    },
    editWallPost() {
      this.edit_mode = true;
    },
    deleteWallPost() {
      this.delete_mode = true;
    },
    cancelEdit() {
      this.edit_mode = false;
    },
    performTagSearch(tag) {
      this.$emit('tag_search', tag);
    },
    async editSaved(updatedPost) {
      if (updatedPost.text_entry == null || updatedPost.text_entry.trim() === '') {
        utils.showToastError('Message text missing', 'Please enter a message to continue');
        return;
      }

      this.is_post_operation = true;

      const newPost = await journal.constructPostObject(
        this.post.id,
        updatedPost.text_entry,
        this.post.via,
        updatedPost.entry_timestamp,
        updatedPost.journal_id,
      );

      try {
        const updatedEntry = await journal.saveEntry(await auth.getUserIdToken(), newPost);
        this.is_post_operation = false;
        this.edit_mode = false;

        utils.showToastSuccess('Success', 'The post was updated successfully.', false);

        // Update the entry in the wall posts
        this.$emit('post_updated', updatedEntry);
      } catch (err) {
        console.error(err);
        this.is_post_operation = false;
        utils.showToastError('Could not update',
          'An error occurred and the post could not be updated.', true);
      }
    },
    cancelDelete() {
      this.delete_mode = false;
    },
    async deletePost() {
      this.is_delete_operation = true;
      this.delete_mode = false;

      try {
        await journal.deleteEntry(await auth.getUserIdToken(), this.post.id);
        this.is_delete_operation = false;

        // Remove the entry from the dashboard page
        this.$emit('post_deleted', this.post.id);

        // Show a toast message that the post was deleted successfully
        utils.showToastSuccess('Success', 'The post was deleted successfully.', false);
      } catch (err) {
        console.error(err);
        this.is_post_operation = false;
        utils.showToastError('Could not delete',
          'An error occurred and the post could not be deleted.', true);
      }
    },
    async moveEntryToJournal(newJournalId) {
      this.is_journal_move_operation = true;

      try {
        const newPost = await journal.moveEntryToJournal(
          await auth.getUserIdToken(), this.post.id, newJournalId,
        );
        this.is_journal_move_operation = false;
        this.$emit('post_moved', newPost);

        utils.showToastSuccess('Great Success!', 'The post was moved successfully to the '
            + 'specified journal.');
      } catch (err) {
        utils.showToastError('Could not move post', 'An unspecified error occurred'
            + 'and the post could not be moved.', false);
      }
    },
  },
};
</script>

<style scoped>

</style>
