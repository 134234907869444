<template>
  <navbar></navbar>

  <div class="main-container">
    <div class="row" v-if="loggedInUser">
      <div class="col-md-6 col-sm-12">
        <div v-if="journalList == null || journalList.length === 0">
          <h3>Your Wall</h3>
        </div>
        <div v-if="journalList != null && journalList.length > 0">
          <div class="dropdown">
            <h3 class="dropdown-toggle" type="button"
                    id="dropdownMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <span v-if="currentJournal">{{currentJournal.name}}</span>
              <span v-if="!currentJournal">Your Wall</span>
            </h3>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:"
                 @click="switchJournal(j.id)"
                 v-for="j in otherSelectableJournals" :key="j.id">
                {{j.name}}
              </a>
              <div v-if="otherSelectableJournals.length > 0 && currentJournal != null"
                   class="dropdown-divider"></div>
              <a class="dropdown-item"
                 v-if="currentJournal != null"
                 @click="switchJournal(null)" href="javascript:">
                Back to the Wall</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-sm-left text-md-right">
        <button type="button"
                @click="createNewEntry()"
                class="btn btn-sm btn-primary">
          <i class="fa fa-plus fa-fw"></i>
          New Entry</button>
      </div>
    </div>

    <div class="row margin-top-element">
      <div class="col-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="fa fa-search"></i>
          </span>
          </div>
          <input type="text" id="txt-wall-search" class="wide form-control"
                 v-model.trim="searchString"
                 @input="performSearch()"
                 placeholder="Search" aria-label="Search">

          <div id="grp-clear-search" class="input-group-append"
               v-show="this.searchString.length > 0">
          <span class="input-group-text">
            <a id="lnk-clear-search" href="javascript:" @click="clearSearch();">
              <i class="fa fa-times"></i>
            </a>
          </span>
          </div>
        </div>
      </div>
    </div>

    <div class="center aligned" v-show="loading_data">
      <loader></loader>
    </div>

    <div class="wall-posts">
      <div v-if="new_entry">
        <div class="wall-post">
          <div v-if="is_post_creation_mode" class="progress-line"></div>
          <post-edit
              @onSave="saveNewEntry"
              @onCancel="cancelNewEntry"
              post_id=""
              entry_timestamp=""
              :all_journals="journalList"
              :journal="currentJournal"
              :save_in_progress="is_post_creation_mode"
              text_entry=""></post-edit>
        </div>
      </div>

      <div id="wall-posts-container" v-if="wall_posts && wall_posts.length > 0">
        <post
            v-for="wall_post in wall_posts"
            :post="wall_post"
            :key="wall_post.id"
            :journal="currentJournal"
            :all_journals="journalList"
            @post_moved="postUpdated"
            @post_updated="postUpdated"
            @post_deleted="postDeleted"
            @tag_search="performTagSearch">
        </post>
      </div>
      <div class="row" v-if="!loading_data && (!wall_posts || wall_posts.length === 0)">
        <div class="col-12 wall-post">
          <div class="post-data">
            Nothing to display here. Either no results were found or no posts have
            been created as yet. <br />
            <br />
            Create a new post now by sending an email to:
            <strong><a :href="'mailto:' + emailForPosts">{{emailForPosts}}</a></strong> from your
            registered email address.<br/>
            <em>Or, you can use the <code>New Entry</code> button on the top of your page to
            do it the old fashioned way!</em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Loader from '@/components/Loader.vue';
import Post from '@/components/Post.vue';
import auth from '@/app/auth';
import journal from '@/app/journal';
import utils from '@/app/utils';
import userInfo from '@/app/user-info';

import _ from 'lodash';
import Mark from 'mark.js';
import MiniSearch from 'minisearch';
import PostEdit from '@/components/PostEdit.vue';

let miniSearch = null;
let pingCheck = null;

export default {
  components: {
    PostEdit,
    Post,
    Loader,
    Navbar,
  },
  async beforeCreate() {
    // Check if user needs to be onboarded
    const user = await userInfo.fetchUser(await auth.getUserIdToken());
    this.loggedInUser = user;
    if (user != null && !user.on_boarded) {
      await this.$router.push('/Setup');
    }
    if (user != null) {
      this.journalList = user.journal;
    }
  },
  computed: {
    emailForPosts() {
      return process.env.VUE_APP_EMAILS_FOR_POSTS;
    },
    otherSelectableJournals() {
      if (this.currentJournal === null) {
        return this.journalList;
      }

      return _.filter(this.journalList, (x) => x.id !== this.currentJournal.id);
    },
  },
  mounted() {
    this.refreshPosts();
    this.setupPingCheck();
  },
  data() {
    return {
      loading_data: false,
      allJournalEntries: [],
      currentLoadedJournalEntries: [],
      wall_posts: [],
      searchString: '',
      new_entry: false,
      is_post_creation_mode: false,
      loggedInUser: null,
      journalList: null,
      currentJournal: null,
    };
  },
  methods: {
    async refreshPosts() {
      this.loading_data = true;

      try {
        // If we don't have all the journal entries, fetch them the first time from the API
        // Once fetched, we maintain the list from the page (add / update / delete)
        if (this.allJournalEntries.length === 0) {
          this.allJournalEntries = await journal.fetchAllEntries(await auth.getUserIdToken());
        }

        let journalEntries = this.allJournalEntries;

        // If there is a journal selected, fetch entries for that journal, else return all entries
        let journalSpecificEntries = _.clone(this.allJournalEntries);
        if (this.currentJournal) {
          journalSpecificEntries = _.filter(this.allJournalEntries,
            { journal_id: this.currentJournal.id });
        }

        // Put these in the right order
        journalEntries = _.orderBy(journalSpecificEntries, ['entry_timestamp'], ['desc']);

        this.loading_data = false;
        this.wall_posts = _.clone(journalEntries);
        this.currentLoadedJournalEntries = _.clone(journalEntries);

        miniSearch = new MiniSearch({
          // fields to index for full-text search
          fields: ['text_entry'],
          // fields to return with search results
          storeFields: ['entry_timestamp', 'id', 'text_entry', 'via', 'tags', 'attachments', 'journal_id'],
          tokenize: (string) => string.split(' '),
        });

        miniSearch.addAll(journalEntries);
      } catch (err) {
        utils.showToastError('Could not fetch entries',
          'An error occurred while fetching the latest journal entries. <br />'
            + 'Please refresh the page.', true);
      }
    },
    performSearch() {
      if (this.searchString === '') {
        this.wall_posts = _.clone(this.currentLoadedJournalEntries);
      } else {
        this.wall_posts = _.orderBy(miniSearch.search(this.searchString, { prefix: true }),
          ['entry_timestamp'], ['desc']);
      }

      this.markText(this.searchString);
    },
    clearSearch() {
      this.searchString = '';
      this.performSearch();
    },
    markText(text) {
      const options = {
        exclude: [
          '.post-top-bar *',
        ],
      };
      const markInstance = new Mark(document.querySelector('div.wall-posts'));
      markInstance.unmark({
        done() {
          markInstance.mark(text, options);
        },
      });
    },
    createNewEntry() {
      this.new_entry = true;
    },
    cancelNewEntry() {
      this.new_entry = false;
    },
    async saveNewEntry(newPost) {
      if (newPost.text_entry == null || newPost.text_entry.trim() === '') {
        utils.showToastError('Message text missing', 'Please enter a message to continue');
        return;
      }

      this.is_post_creation_mode = true;
      const post = await journal.constructPostObject(null,
        newPost.text_entry, 'Web', newPost.entry_timestamp, newPost.journal_id);

      const savedPost = await journal.saveEntry(await auth.getUserIdToken(), post);

      this.allJournalEntries.push(savedPost);
      utils.showToastSuccess('Great success!', 'Your new post was created successfully!');

      this.is_post_creation_mode = false;
      this.new_entry = false;

      await this.refreshPosts();
    },
    async postUpdated(updatedPost) {
      // Replace details from the existing post
      const postInArray = _.find(this.currentLoadedJournalEntries, { id: updatedPost.id });
      if (postInArray !== undefined) {
        this.allJournalEntries = _.map(this.allJournalEntries,
          (x) => (x.id === updatedPost.id ? updatedPost : x));

        await this.refreshPosts();
      }
    },
    async postDeleted(deletedPostId) {
      this.allJournalEntries = _.filter(this.allJournalEntries,
        (x) => (x.id !== deletedPostId));

      await this.refreshPosts();
    },
    setupPingCheck() {
      if (pingCheck == null) {
        pingCheck = setInterval(async () => {
          // Check every 5 minutes = 300,000ms
          try {
            await auth.checkPing(await auth.getUserIdToken());
          } catch {
            window.location.href = '/logout?timeout=1';
          }
        }, 300000);
      }
    },
    performTagSearch(tag) {
      this.searchString = `#${tag}`;
      this.performSearch();
    },
    async switchJournal(journalId) {
      if (journalId == null) {
        this.currentJournal = null;
      } else {
        this.currentJournal = _.find(this.journalList, { id: journalId });
      }

      await this.refreshPosts();
    },
  },
};
</script>
