import axios from 'axios';
import moment from 'moment';
import utils from './utils';

const BASE_URL = `${process.env.VUE_APP_API_URL}/journal`;

const constructPostObject = async (id, textEntry, via, timeStamp, journalId) => {
  const result = {
    text_entry: textEntry,
    via,
    entry_timestamp: timeStamp,
  };

  if (id) {
    result.id = id;
  }

  if (journalId) {
    result.journal_id = journalId;
  }

  return result;
};

/** *
 * Given a valid token ID for a user, fetches all the journal entries of the user.
 *
 * @param token
 * @return Array(post)
 */
const fetchAllEntries = async (token) => {
  const resp = axios.get(BASE_URL, utils.getAxiosConfig(token));
  return (await resp).data;
};

/** *
 * Saves a wall post. Either creates a new one or updates an existing one.
 *
 * @param token
 * @param post
 * @return post
 */
const saveEntry = async (token, post) => {
  const data = {
    via: post.via ?? 'Web',
    text_entry: post.text_entry,
  };

  if (post.entry_timestamp) {
    data.entry_timestamp = moment(post.entry_timestamp).format();
  }

  if (post.journal_id) {
    data.journal_id = post.journal_id;
  }

  // Whether to update or create a new entry
  const url = post.id ? (`${BASE_URL}/${post.id}`) : BASE_URL;

  let resp = null;
  if (post.id) {
    // Update
    resp = axios.patch(url, data, utils.getAxiosConfig(token));
  } else {
    // Create a new entry
    resp = axios.post(url, data, utils.getAxiosConfig(token));
  }

  return (await resp).data;
};

/** *
 * Deletes the specified wall post
 * @param token
 * @param id
 * @return true if deleted successfully. Else, throws an error.
 */
const deleteEntry = async (token, id) => {
  const url = `${BASE_URL}/${id}`;

  await axios.delete(url, utils.getAxiosConfig(token));
  return true;
};

/** *
 * Moves a post entry to a different journal
 *
 * @param token
 * @param postId
 * @param newJournalId
 * @return {Promise<any>}
 */
const moveEntryToJournal = async (token, postId, newJournalId) => {
  const url = `${BASE_URL}/move/${postId}`;
  const data = {
    new_journal_id: newJournalId,
  };

  const result = axios.post(url, data, utils.getAxiosConfig(token));
  return (await result).data;
};
export default {
  constructPostObject,
  fetchAllEntries,
  saveEntry,
  deleteEntry,
  moveEntryToJournal,
};
